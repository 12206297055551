<template>
  <div id="PicassoGallery">
    <!-- Gallery Banner ====================================== -->
    <div class="gallery_banner">
      <div class="wrap">
        <div class="picasso_logo">
          <img src="/img/threespace/picasso/little_picasso_logo.png" />
        </div>
        <div class="title_image">
          <img src="/img/threespace/picasso/title_shinhan.png" /><br />
          <img src="/img/threespace/picasso/title_x.png" />
          <img src="/img/threespace/picasso/title_3spaceart.png" />
        </div>
        <p class="info">{{ $t("gallery.picasso.mainText") }}</p>
        <p class="date">
          {{ $t("gallery.picasso.mainDate") }}
        </p>
        <b-button @click="onClickGoShinhanSite">
          {{ $t("gallery.picasso.mainMore") }}
        </b-button>
      </div>
    </div>

    <!-- Gallery Ranking slider ====================================== -->
    <div
      class="gallery_ranking"
      v-if="!isBusy"
    >
      <div class="ranking_title">
        {{ $t("gallery.picasso.awarded") }}
      </div>
      <slick :options="_slickOptions">
        <template v-for="(item, i) in galleryCollectionRankingList">
          <div
            class="card_list_item cursor-pointer"
            :id="'galleryItem' + item.idx"
            @click="showCollectionDetail(item)"
            :key="i"
          >
            <div class="template_box" :class="{ no_template: !showTemplate }">
              <img src="/img/threespace/picasso/little_picasso_temp.png" />
            </div>
            <div
              class="card_media_box"
              @contextmenu.prevent.stop
              :class="{ 'skeleton_display_none': !isBusy }"
            >
              <CardHoverBox
                :favoritedCnt="item.favoritedCnt"
                :pageViewCnt="item.product.pageViewCnt"
                :isExternalCollection="true" />
              <template>
                <template v-if="!item.video">
                  <img
                    :src="item.imageUrl"
                    @error="replaceByDefault"
                  />
                </template>
                <template v-else>
                  <video
                    :poster="replaceDisplayPosterUrl(item)"
                    :src="replaceDisplayCardUrl(item)"
                    type="video/webm"
                    muted
                    loop
                    autoplay
                    playsinline
                  ></video>
                </template>
              </template>
              <b-skeleton-img
                no-aspect
                width="100%"
                height="100%"
              ></b-skeleton-img>
            </div>
            <div class="card_title_box" v-if="!isBusy">
              <h3>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ item.title || item.titleEn }}
                </template>
                <template v-else>
                  {{ item.titleEn || item.title }}
                </template>
              </h3>
              <p class="artist_name">
                <span class="name">
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artist || item.artistEn }}
                  </template>
                  <template v-else>
                    {{ item.artistEn || item.artist }}
                  </template>
                </span>
                <span class="company" :class="{'has_ranking' : item.ranking !== undefined}">
                  {{ item.company }}<br />
                  <span class="ranking">
                    {{ item.part.replace(" ","") }}
                    <template v-if="item.ranking === 1">
                      대상
                    </template>
                    <template v-else-if="item.ranking === 2">
                      최우수상
                    </template>
                    <template v-else-if="item.ranking === 3">
                      우수상
                    </template>
                    <template v-else-if="item.ranking === 4">
                      특선
                    </template>
                  </span>
                </span>
              </p>
            </div>
            <div class="card_title_box" v-else>
              <p class="artist_name">
                <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
                <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
              </p>
            </div>
            <div class="card_price_box" v-if="!isBusy">
              <div class="text_wrap">
                <FavoritedButton
                  :favoritedItem="item"
                  @onClickFavoritedButton="onClickFavoritedButton"
                />
              </div>
              <div class="icon_wrap" :class="{ no_template: !showTemplate }">
                <img class="shinhan_logo" src="/img/threespace/picasso/popup_shinhan.png" />
              </div>
            </div>
            <div class="card_price_box" v-else>
              <span>
                <b-skeleton type="avatar" class="float-right" width="25px" height="25px"></b-skeleton>
              </span>
            </div>
          </div>
        </template>
      </slick>
    </div>

    <!-- Gallery Filter ====================================== -->
    <div class="gallery_filter">
      <ul class="tab_menu">
        <template v-for="(item, i) in tabMenuOptions">
          <li :key="`tabmenu${i}`">
            <a
              @click="onClickTabMenu(item)"
              :class="{active : currentTab.text === item.text}"
              :title="item.text"
            >
              {{ item.text }}
            </a>
          </li>
        </template>
      </ul>
      <div class="filter_box">
        <!--
        <b-form-checkbox
          class="temp_show"
          switch
          v-model="showTemplate"
        ></b-form-checkbox>
        -->
        <div class="input_box">
          <b-form-input
            type="text"
            placeholder="어린이명, 학교명을 입력해주세요"
            autocomplete="off"
            v-model="externalParams.searchKeyword"
            @keypress.enter.prevent="onSearch()"
          />
          <b-button
            @click="onSearchReset()"
            class="reset_btn"
            v-if="externalParams.searchKeyword !== ''"
          >
            <img src="/img/threespace/icon/search_reset_icon.png" />
          </b-button>
          <b-button @click="onSearch()" class="search_btn px-0">
            <img src="/img/threespace/icon/search_icon.png" />
          </b-button>
        </div>
      </div>
    </div>

    <!-- Gallery List ====================================== -->
    <div class="gallery_list_wrap">
      <template
        class="row"
        v-if="
          galleryCollectionList === undefined ||
          galleryCollectionList === null ||
          galleryCollectionList.content.length === 0
        "
      >
        <div class="col-12 text-center" key="0">
          <h5 class="pb-5 my-5 text-gray">
            {{ $t("mypage.noHistoryCollection") }}
          </h5>
        </div>
      </template>
      <div class="row" v-else>
        <div
          class="col-xl-4 col-lg-4 col-md-6 col-sm-6 px-3 position-relative"
          v-for="(item, i) in galleryCollectionList.content"
          :key="i"
          :ref="'cartItem' + item.idx"
        >
          <div
            class="card_list_item cursor-pointer"
            :id="'galleryItem' + item.idx"
            @click="showCollectionDetail(item)"
          >
            <div class="template_box" :class="{ no_template: !showTemplate }">
              <img src="/img/threespace/picasso/little_picasso_temp.png" />
            </div>
            <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
              <div
                class="card_media_box"
                @contextmenu.prevent.stop
                :class="{ 'skeleton_display_none': !isBusy }"
              >
                <CardHoverBox
                  :favoritedCnt="item.favoritedCnt"
                  :pageViewCnt="item.product.pageViewCnt"
                  :isExternalCollection="true" />
                <template>
                  <template v-if="!item.video">
                    <img
                      :src="item.imageUrl"
                      @error="replaceByDefault"
                    />
                  </template>
                  <template v-else>
                    <video
                      :poster="replaceDisplayPosterUrl(item)"
                      :src="replaceDisplayCardUrl(item)"
                      type="video/webm"
                      muted
                      loop
                      autoplay
                      playsinline
                    ></video>
                  </template>
                </template>
                <b-skeleton-img
                  no-aspect
                  width="100%"
                  height="100%"
                ></b-skeleton-img>
              </div>
            </v-lazy>
            <div class="card_title_box" v-if="!isBusy">
              <h3>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ item.title || item.titleEn }}
                </template>
                <template v-else>
                  {{ item.titleEn || item.title }}
                </template>
              </h3>
              <p class="artist_name">
                <span class="name">
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artist || item.artistEn }}
                  </template>
                  <template v-else>
                    {{ item.artistEn || item.artist }}
                  </template>
                </span>
                <span class="company" :class="{'has_ranking' : item.ranking !== undefined}">
                  {{ item.company }}<br />
                  <span class="ranking">
                    <template v-if="item.ranking === 4">
                      {{ item.part.replace(" ","") }} 특선
                    </template>
                  </span>
                </span>
              </p>
            </div>
            <div class="card_title_box" v-else>
              <p class="artist_name">
                <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
                <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
              </p>
            </div>
            <div class="card_price_box" v-if="!isBusy">
              <div class="text_wrap">
                <FavoritedButton
                  :favoritedItem="item"
                  @onClickFavoritedButton="onClickFavoritedButton"
                />
              </div>
              <div class="icon_wrap" :class="{ no_template: !showTemplate }">
                <img class="shinhan_logo" src="/img/threespace/picasso/popup_shinhan.png" />
              </div>
            </div>
            <div class="card_price_box" v-else>
              <span>
                <b-skeleton type="avatar" class="float-right" width="25px" height="25px"></b-skeleton>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- pagination -->
      <div class="row">
        <div class="col-12 text-right">
          <b-pagination
            v-on:change="onPaging"
            :total-rows="galleryCollectionList.totalElements"
            v-model="currentPage"
            :per-page="perPage"
            hide-ellipsis
            limit="10"
            aria-controls="role-function-list"
            class="float-right gallery_pagination"
          >
            <template #first-text>
              <img
                class="page_icon rotate_page_icon"
                src="/img/threespace/icon/pagination_arrow2_icon.png"
              />
            </template>
            <template #prev-text>
              <img
                class="page_icon rotate_page_icon"
                src="/img/threespace/icon/pagination_arrow_icon.png"
              />
            </template>
            <template #next-text>
              <img
                class="page_icon"
                src="/img/threespace/icon/pagination_arrow_icon.png"
              />
            </template>
            <template #last-text>
              <img
                class="page_icon"
                src="/img/threespace/icon/pagination_arrow2_icon.png"
              />
            </template>
            <template #page="{ page }">
              <span :class="{active_num: page === currentPage}">
                {{ page }}
              </span>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FavoritedButton from "@/views/threespace/components/common/FavoritedButton.vue";
import CardHoverBox from "@/views/threespace/components/common/CardHoverBox.vue";

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  name: "PicassoGallery",
  components: {
    FavoritedButton,
    CardHoverBox,
    Slick
  },
  metaInfo() {
    return {
      title: this.$t("meta.littlePicasso.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.littlePicasso.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.littlePicasso.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.littlePicasso.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.littlePicasso.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data() {
    return {
      showRanking: false,
      isBusy: false,
      isLast: false,
      perPage: 30,
      currentPage: 1,
      sort: [
        { id: "createdAt", desc: "desc" }
      ],
      externalParams: {
        idxNft: process.env.VUE_APP_SHINHAN_NFT_IDX,
        searchKeyword: "",
        part: "",
        parts: [],
        rankings: []
      },
      tabMenuOptions: [
        { text: this.$t("gallery.picasso.menu1"), value: "" },
        { text: this.$t("gallery.picasso.menu2"), value: "유치부" },
        { text: this.$t("gallery.picasso.menu3"), value: "초등 저학년부" },
        { text: this.$t("gallery.picasso.menu4"), value: "초등 고학년부" },
      ],
      currentTab: { text: this.$t("gallery.picasso.menu1"), value: "" },
      showTemplate: true,
      slickOptions: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
      },
      mobileSlickOptions: {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.requestGalleryCollections();
    });
  },
  computed: {
    ...mapState("collection", ["galleryCollectionList", "galleryCollectionRankingList"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    _slickOptions() {
      if (this.isMobile) {
        return this.mobileSlickOptions
      } else {
        return this.slickOptions
      }
    }
  },
  methods: {
    ...mapActions("collection", [
      "getGalleryCollections",
      "getGalleryCollectionsRanking",
      "getGalleryCollectionsRankingCount"
    ]),
    ...mapActions("favorited", ["addFavorited", "deleteFavorited", "toggleFavorited"]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickTabMenu(tabItem) {
      this.currentTab = tabItem;
      this.currentPage = 1;
      this.externalParams.part = tabItem.value;
      this.requestGalleryCollections();
    },
    requestGalleryCollections() {
      this.isBusy = true;
      if (this.externalParams.part === "") {
        this.externalParams.parts = ["유치부", "초등 저학년부", "초등 고학년부"];
        this.sort = [
          { id: "ranking", desc: "asc" },
          { id: "updatedAt", desc: "desc" }
        ]
      } else {
        this.sort = [
          { id: "ranking", desc: "asc" },
          { id: "updatedAt", desc: "desc" }
        ]
      }
      this.externalParams.rankings = [1, 2, 3];
      this.getGalleryCollections(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then(() => {
          this.showRanking = false;
          this.getGalleryCollectionsRankingCount(this.externalParams)
            .then(data => {
              // ranking 이 있으면
              if (data !== 0) {
                this.getGalleryCollectionsRanking(this.externalParams)
                  .then(() => {
                    this.isBusy = false;
                    this.showRanking = true;
                  })
                  .catch(() => {
                    this.isBusy = false;
                    this.showRanking = false;
                  })
              } else {
                this.isBusy = false;
                this.showRanking = false;
              }
            })
            .catch(() => {
              this.isBusy = false;
              this.showRanking = false;
            })
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };
      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onPaging(page) {
      this.currentPage = page;
      this.requestGalleryCollections();

      if (this.isMobile) {
        window.scrollTo(0, 500);
      } else {
        window.scrollTo(0, 700);
      }
    },
    showCollectionDetail(item) {
      this.$router.push("/little-picasso/detail/" + item.idx);
    },
    replaceDisplayPosterUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayPoster !== undefined &&
          item.product.displayPoster !== null &&
          item.product.displayPoster !== "" &&
          item.product.displayPoster === "THREESPACE"
        ) {
          return item.product.displayPosterUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayPosterUrl !== undefined && item.product.displayPosterUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayPosterUrl;
        }
      } else {
        return item.imageUrl
      }
    },
    replaceDisplayCardUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayCardFile !== undefined &&
          item.product.displayCardFile !== null &&
          item.product.displayCardFile !== "" &&
          item.product.displayCardFile === "THREESPACE"
        ) {
          return item.product.displayCardUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayCardUrl !== undefined && item.product.displayCardUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayCardUrl;
        }
      } else {
        return item.imageUrl
      }
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then(() => {
              if (
                this.me === undefined ||
                this.me === null ||
                this.me.idx === undefined ||
                this.me.idx === null ||
                this.me.idx === ""
              ) {
                // 로그인
                this.hasKlaytnAddr = false;
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("validation.requiredLogin"),
                  show: true,
                  fontSize: "sm"
                })
              } else {
                // 로그인 되어있음
                if (favoritedItem.favorited) {
                  // true이면 좋아요 헤재
                  this.toggleFavorited(favoritedItem);
                  this.deleteFavorited({idxCollection: favoritedItem.idx});
                } else {
                  // false이면 좋아요
                  this.toggleFavorited(favoritedItem);
                  this.addFavorited({idxCollection: favoritedItem.idx});
                }
              }
            })
        } else {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("validation.requiredLogin"),
            show: true,
            fontSize: "sm"
          })
        }
    },
    onSearch() {
      this.isLast = true;
      this.currentPage = 1;
      this.requestGalleryCollections();
    },
    onSearchReset() {
      this.externalParams.searchKeyword = "";
      this.onSearch();
    },
    onClickGoShinhanSite() {
      window.open("https://www.shinhancard.com/pconts/company/html/promotion/picasso/pcs_info.html", "_blank");
    },
    replaceByDefault(e) {
      e.target.src = '/img/threespace/picasso/empty_img.png'
    }
  }
};
</script>
